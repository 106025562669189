<template>
  <vx-card style="min-height: 500px !important;">
    <!-- POP UP `INCOME` ITEM -->
    <template>
      <vs-popup
        classContent="popup-example income-panel"
        class="income-panel"
        title="ลงรายรับ"
        :active.sync="incomePanelActive"
      >
        <!-- ROW OF BORROW ITEM -->
        <!--  หัวข้อ  -->
        <!--  Add this to data part  (import vSelect from vue-select) -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->
        <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >หัวข้อ</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <v-select
              style="width: 100%;"
              v-model="incomeSelected"
              :options="incomeOptions"
              :select="incomeSelected"
            ></v-select>
          </vs-col>
        </vs-row>
        <!--  จำนวนเงิน  -->
        <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >จำนวนเงิน</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <CurrencyValue
              class="w-full"
              v-model="incomeAmount"
            ></CurrencyValue>
          </vs-col>
        </vs-row>

        <!--  คอมเม้น  -->
        <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >คอมเม้น</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-textarea
              class="w-full"
              name="incomeComment"
              v-model="incomeComment"
            />
          </vs-col>
        </vs-row>

        <!--  วันที่ลงรายการ  -->
        <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >วันที่ลงรายการ</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            {{ formatDate(incomeUpdateDate) }}

            <!-- <datepicker
              style="width:100%;"
              placeholder
              format="yyyy-MM-dd"
              v-model="incomeUpdateDate"
            ></datepicker> -->
          </vs-col>
        </vs-row>

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="margin-top:40px; padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="incomePanelActive = false"
            type="filled"
            >ยกเลิก</vs-button
          >
          <vs-button
            style="background-color:#7ED321 !important; margin-left:15px;"
            type="filled"
            @click="addIncome()"
            >ลงรายรับ</vs-button
          >
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
    </template>
    <!-- POP UP EXPENSE ITEM -->
    <template>
      <vs-popup
        class="expense-panel"
        classContent="popup-example"
        title="ลงรายจ่าย"
        :active.sync="expensePanelActive"
      >
        <!-- ROW OF EXPENSE ITEM -->
        <!--  Add this to data part  (import vSelect from vue-select) -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->
        <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >หัวข้อ</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <v-select
              style="width: 100%;"
              v-model="expenseSelected"
              :options="expenseOptions"
              :select="expenseSelected"
            ></v-select>
          </vs-col>
        </vs-row>
        <!--  จำนวนเงิน  -->
        <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >จำนวนเงิน</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <CurrencyValue
              class="w-full"
              v-model="expenseAmount"
            ></CurrencyValue>
          </vs-col>
        </vs-row>

        <!--  คอมเม้น  -->
        <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >คอมเม้น</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            <vs-textarea
              class="w-full"
              name="expenseComment"
              v-model="expenseComment"
            />
          </vs-col>
        </vs-row>

        <!--  วันที่ลงรายการ  -->
        <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="form-label px-2"
            >วันที่ลงรายการ</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="6"
            vs-sm="6"
            vs-xs="6"
            class="form-label px-2"
          >
            {{ formatDate(expenseUpdateDate) }}
            <!-- <datepicker
              style="width:100%;"
              placeholder
              format="yyyy-MM-dd"
              v-model="expenseUpdateDate"
            ></datepicker> -->
          </vs-col>
        </vs-row>

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style=" margin-top:30px;padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="expensePanelActive = false"
            type="filled"
            >ยกเลิก</vs-button
          >
          <vs-button
            style="background-color:#e95455 !important; margin-left:15px;"
            type="filled"
            @click="addExpense()"
            >ลงรายจ่าย</vs-button
          >
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
    </template>
    <!-- HEADER -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-sm="4"
        vs-xs="12"
        class="px-2"
      >
        <v-date-picker
          style="width:100%;"
          :locale="{ id: 'th', firstDayOfWeek: 2, masks: { L: 'DD/MM/YYYY' } }"
          mode="range"
          v-model="calendarData"
        />
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-sm="4"
        vs-xs="12"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="6"
            class="px-2"
          >
            <vs-button
              @click="filterAll()"
              style="width:100%;"
              color="primary"
              type="filled"
              >ค้นหา</vs-button
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="6"
            class="px-2"
          >
            <vs-button
              @click="resetAll()"
              style="width:100%;"
              color="warning"
              type="filled"
              >reset</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="4"
        vs-sm="4"
        vs-xs="12"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          ></vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          >
            <div @click="incomePanel($event)" class="green-round-btn">
              ลงรายรับ
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          >
            <div @click="expensePanel($event)" class="red-round-btn">
              ลงรายจ่าย
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <!-- BRANCHES -->
    <!-- <vs-row vs-w="12">
      <ul class="demo-alignment">
        <li v-for="(b, index) in branches" :key="index">
          <vs-checkbox v-model="branchCheckbox" :vs-value="b.branchId">{{ b.branchName }}</vs-checkbox>
        </li>
      </ul>
    </vs-row> -->
    <!-- BRANCHES -->
    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table
      style="z-index:1;"
      pagination
      max-items="20"
      search
      :data="orders"
    >
      <template style="z-index:1 !important;" slot="thead">
        <vs-th sort-key="updateDate">วันเดือนปี</vs-th>
        <vs-th sort-key="updateBy">ลงรายการ</vs-th>
        <vs-th sort-key="description">รายละเอียด</vs-th>
        <vs-th sort-key="monetaryCategory">ประเภท</vs-th>
        <vs-th sort-key="branch">สาขา</vs-th>
        <vs-th sort-key="income">รายรับ</vs-th>
        <vs-th sort-key="expense">รายจ่าย</vs-th>
        <vs-th>การจัดการ</vs-th>
      </template>

      <template style="z-index:1 !important;" slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td style="min-width:120px;font-family: SukhumvitSet-Medium;">{{
            formatDate(tr.updateDateTime)
          }}</vs-td>
          <vs-td style="font-family: SukhumvitSet-Medium;">{{
            tr.updateBy
          }}</vs-td>

          <vs-td style="font-family: SukhumvitSet-Medium;">{{
            tr.description
          }}</vs-td>

          <vs-td style="padding-left:20px; font-family: SukhumvitSet-Medium;">{{
            tr.monetaryCategory
          }}</vs-td>

          <vs-td style="padding-left:20px; font-family: SukhumvitSet-Medium;">{{
            tr.branchName
          }}</vs-td>
          <vs-td
            v-if="tr.income === undefined || tr.income === null"
            style="padding-left:20px; font-weight: bold; color:#7ED321; "
            >-</vs-td
          >

          <vs-td
            style="padding-left:20px; font-weight: bold; color:#7ED321; "
            v-else
            >{{ formatPrice(tr.income) }}</vs-td
          >
          <vs-td
            v-if="tr.expense === undefined || tr.expense === null"
            style="padding-left:20px; font-weight: bold; color:#E95455; "
            >-</vs-td
          >
          <vs-td
            v-else
            style="padding-left:20px; font-weight: bold; color:#E95455; "
            >{{ formatPrice(tr.expense) }}</vs-td
          >
          <vs-td>
            <i
              @click="remove(tr.accountingId)"
              class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
              style="order: 0;text-align:left;  font-size:25px; color:red;"
              >delete_forever</i
            >
          </vs-td>

          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
    <!-- BEGIN: MONETARY SUMMATION -->
    <div class="grid-layout-container alignment-block" style="width:100%;">
      <vs-row
        vs-w="12"
        style="padding: 10px 20px;  background-color: #f4f4f4;"
        class="grand-total the-green"
        vs-type="flex"
        vs-justify="flex-end"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="6"
          style="background-color: #f4f4f4;"
          >รายรับทั้งหมด</vs-col
        >
        <vs-col
          style="padding-left: 30px; background-color: #f4f4f4;"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="6"
          >{{ formatPrice2Point(incomeTotal) }}</vs-col
        >
      </vs-row>
      <vs-row
        vs-w="12"
        style="padding: 10px 20px;  background-color: #f4f4f4;"
        class="grand-total the-red"
        vs-type="flex"
        vs-justify="flex-end"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="6"
          style="background-color: #f4f4f4;"
          >รายจ่ายทั้งหมด</vs-col
        >
        <vs-col
          style="padding-left: 30px; background-color: #f4f4f4;"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="6"
          >{{ formatPrice2Point(expenseTotal) }}</vs-col
        >
      </vs-row>
      <vs-row
        vs-w="12"
        style="padding: 10px 20px;  background-color: #f4f4f4;"
        class="grand-total the-blue"
        vs-type="flex"
        vs-justify="flex-end"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="6"
          style="background-color: #f4f4f4;"
          >รวมยอดเงินคงเหลือ</vs-col
        >
        <vs-col
          style="padding-left: 30px; background-color: #f4f4f4;"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-lg="2"
          vs-sm="4"
          vs-xs="6"
          >{{ formatPrice2Point(grandTotal) }}</vs-col
        >
      </vs-row>
    </div>
    <!-- END:MONETARY SUMMATION -->
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
// import HotelDatePicker from "vue-hotel-datepicker";
import vSelect from "vue-select";
import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
// window.addEventListener("load", function() {
//   var x = document.getElementsByClassName("tr-expand ");
//   for (var ii = 0; ii < x.length; ii++) {
//     x[ii].innerHTML = "12";
//   }
// });
export default {
  mixins: [shapeFormat],
  data() {
    return {
      searchDate: null,
      searchDateEnd: null,
      calendarData: {},
      moneycode: [],
      startingDateValue: new Date(),
      endingDateValue: new Date(),
      theCheckIn: new Date(),
      theCheckOut: null,
      currentUser: "",
      thisBranch: {},
      currentBranch: "",
      expenseAmount: "0",
      expenseComment: "",
      expenseUpdateDate: new Date(),
      incomeUpdateDate: new Date(),
      incomeAmount: "0",
      incomeComment: "",
      incomeSelected: "เงินรายเดือน",
      incomeOptions: ["เงินรายเดือน", "ส่งของ", "ยอดยกมา"],
      expenseSelected: "ค่าไลน์แมน",
      expenseOptions: [
        "ค่าไลน์แมน",
        "ค่าซ่อมแซมร้าน สาขาเมืองทอง",
        "ค่าซ่อมซิป",
        "ค่ากับข้าว",
        "ค่่าน้ำ"
      ],
      expensePanelActive: false,
      incomePanelActive: false,
      thai: {
        "day-names": ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
        "check-in": "วันเริ่ม",
        "check-out": "วันสุดท้าย",
        "month-names": [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม"
        ]
      },
      branchCheckbox: [],
      branches: [],

      statusToReturn: "รับชุดแล้ว",
      statusToReturnOption: [
        { text: "รับชุดแล้ว", value: "รับชุดแล้ว" },
        { text: "ยกเลิก", value: "ยกเลิก" }
      ],
      popupActive: false,
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: "เงินสด",
      payment_option: [
        { text: "เงินสด", value: "เงินสด" },
        { text: "โอนเงิน", value: "โอนเงิน" },
        { text: "Alipay/wechat", value: "Alipay/wechat" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      orders: []
    };
  },
  async created() {
    // this.branches = await this.loadBranch();
    // var keys = ["branchId"];
    // var onedimension = this.automapper(this.branches, keys);
    // this.branchCheckbox = this.convertToArray(onedimension, "branchId");
  },

  components: {
    Prism,
    Datepicker,
    // HotelDatePicker,
    "v-select": vSelect,
    CurrencyValue
  },
  computed: {
    //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch() {
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches() {
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission() {
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    }
    //  *** BEGIN : 2020-11-28 : Search By branches
    // incomeTotal: {
    //   get: function() {
    //     var total = 0;
    //     for (var i = 0; i < this.orders.length; i++) {
    //       total = total + this.formatNumber(this.orders[i].income);
    //     }
    //     return total;
    //   },
    //   set: function() {}
    // },
    // expenseTotal: {
    //   get: function() {
    //     var total = 0;
    //     for (var i = 0; i < this.orders.length; i++) {
    //       total = total + this.formatNumber(this.orders[i].expense);
    //     }
    //     return total;
    //   },
    //   set: function() {}
    // },
    // grandTotal: {
    //   get: function() {
    //     var total = 0;
    //     total =
    //       this.formatNumber(this.incomeTotal) -
    //       this.formatNumber(this.expenseTotal);
    //     return total;
    //   },
    //   set: function() {}
    // }
  },
  watch: {
    async getCurrentBranch() {
      // await this.reload();
      this.resetAll();
    },
    expensePanelActive(value) {
      if (value === false) {
        this.initExpense();
      }
    },
    incomePanelActive(value) {
      if (value === false) {
        this.initIncome();
      }
    },
    calendarData: {
      handler(value) {
        this.searchDate = this.formatDate(value.start);
        this.searchDateEnd = this.formatDate(value.end);
      },
      deep: true
    },
    orders: {
      handler() {
        this.incomeTotal = 0;
        this.expenseTotal = 0;
        this.grandTotal = 0;

        for (var i = 0; i < this.orders.length; i++) {
          this.incomeTotal =
            this.formatNumber(this.incomeTotal) +
            this.formatNumber(this.orders[i].income);

          this.expenseTotal =
            this.formatNumber(this.expenseTotal) +
            this.formatNumber(this.orders[i].expense);
        }

        this.grandTotal =
          this.formatNumber(this.incomeTotal) -
          this.formatNumber(this.expenseTotal);

        console.log("this.incomeTotal >>>> ", this.incomeTotal);
        console.log("this.expenseTotal >>>> ", this.expenseTotal);
      },
      deep: true
    },
    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    branchCheckbox() {
      // console.log("watch: branchCheckbox >>> ", value);

      this.filterAll();
    }
  },

  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("username"));

    this.currentBranch = this.$store.state.auth.mybranchid;
    var br = await this.loadBranch();
    this.thisBranch = await br.find(
      x => x.branchId === this.$store.state.auth.mybranchid
    );

    // this.branches = await this.loadBranch();
    // var keys = ["branchId"];
    // this.branchCheckbox = this.automapper(this.branches, keys);
    // console.log("created branchCheckbox >>> ", this.branchCheckbox);

    var res = await this.$http.get(
      this.$store.state.apiURL + "/api/moneycode",
      this.$store.state.auth.apiHeader
    );

    var moneyres = res.data;

    this.moneycode = await moneyres;
    this.income = await this.moneycode.filter(i => i.codeType === "รายรับ");
    this.incomeSelected = "ไม่ระบุ";
    this.incomeOptions = ["ไม่ระบุ"];
    for (var k = 0; k < this.income.length; k++) {
      this.incomeOptions = this.incomeOptions.concat(this.income[k].code);
    }

    // console.log("this.incomeOptions >>>> ", this.incomeOptions);

    this.expense = await this.moneycode.filter(i => i.codeType === "รายจ่าย");
    this.expenseSelected = "ไม่ระบุ";
    this.expenseOptions = ["ไม่ระบุ"];
    for (var l = 0; l < this.expense.length; l++) {
      this.expenseOptions = this.expenseOptions.concat(this.expense[l].code);

      // console.log("this.expenseOptions >>>> ", this.expenseOptions);
    }

    // this.resetCalendar();

    await this.reload();
  },

  methods: {
    filterAll() {
      this.orders = this.filteringMore(
        this.originalList,
        [this.getCurrentBranch.branchId],
        "branchId"
      );

      // console.log("this.getCurrentBranch", this.getCurrentBranch.branchId);

      // console.log("Filtered bu branch ID ", this.orders);

      //  *** BEGIN : 2020-11-28 : Search By branches
      if(this.calendarData === null )
      {
        this.calendarData = {};
      }
      if (
        Object.entries(this.calendarData).length === 0
      ) {
        this.searchDate = null;
        this.searchDateEnd = null;
      }
      //  *** BEGIN : 2020-11-28 : Search By branches

      if (this.searchDate !== null && this.searchDateEnd !== null) {
        var searchDate = this.formatDate(this.searchDate);
        var searchDateEnd = this.formatDate(this.searchDateEnd);
        if (searchDate && searchDateEnd) {
          this.orders = this.searchByDate(
            this.orders,
            searchDate,
            searchDateEnd,
            "updateDateTime"
          );
        }
        // console.log("AFTER searchByDate >>> ", this.orders);
      }
    },
    async resetAll() {
      this.searchDate = null;
      this.searchDateEnd = null;

      // this.resetCalendar();
      await this.reload();
      this.calendarData = {};
    },
    // resetCalendar() {
    //   var inp = document.getElementsByClassName("datepicker__input");
    //   inp[0].innerHTML = "วันเริ่ม";
    //   inp[1].innerHTML = "วันสุดท้าย";
    //   this.theCheckIn = null;
    //   this.theCheckOut = null;
    // },

    async loadBranch() {
      var response = null;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
        response = null;
      }

      if (response !== null) return await response.data;
      else return null;
    },
    async reload() {
      this.$vs.loading();

      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/accounting",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }
      if (res !== null) {
        this.orders = await res.data;
        // if (this.$store.state.auth.permission === "staff") {
        var list = await res.data;
        this.orders = await list.filter(
          ff => ff.branchId === this.getCurrentBranch.branchId
        );
        // }

        this.originalList = await this.orders;
      }
      // setTimeout(() => {
      //   this.$vs.loading.close();
      // }, 300);

      this.$vs.loading.close();

      return res.data;
    },
    async addIncome() {
      this.$vs.loading({ type: "radius" });
      var item = {
        description: this.incomeComment,
        monetaryCategory: this.incomeSelected,
        branchId: this.getCurrentBranch.branchId,
        branchName: this.getCurrentBranch.branchName,
        updateBy: this.currentUser,
        income: this.incomeAmount.toString(),
        expense: "0"
      };
      // console.log('item >>>> ',item);

      await this.create(item);
      await this.reload();
      setTimeout(() => {
        this.incomePanelActive = false;
        this.initExpense();
        this.$vs.loading.close();
      }, 1000);
    },

    async addExpense() {
      this.$vs.loading({ type: "radius" });
      var item = {
        description: this.expenseComment,
        monetaryCategory: this.expenseSelected,
        branchId: this.getCurrentBranch.branchId,
        branchName: this.getCurrentBranch.branchName,
        updateBy: this.currentUser,
        income: "0",
        expense: this.expenseAmount.toString()
      };

      await this.create(item);
      await this.reload();
      setTimeout(() => {
        this.expensePanelActive = false;
        this.initExpense();
        this.$vs.loading.close();
      }, 1000);

      // this.orders.push(anExpense);
      // this.initExpense();
      // this.expensePanelActive = false;
    },
    async create(item) {
      var res = await this.$http.post(
        this.$store.state.apiURL + "/api/accounting",
        item,
        this.$store.state.auth.apiHeader
      );
      return res.data;
    },
    async remove(itemid) {
      var res = await this.$http.delete(
        this.$store.state.apiURL + "/api/accounting/" + itemid,
        this.$store.state.auth.apiHeader
      );
      await this.reload();
      return res.data;
    },
    removeItem: function(indextr) {
      this.orders.splice(indextr, 1);
    },
    initIncome() {
      this.incomeUpdateDate = this.formatDate(new Date());
      this.updateBy = "";
      this.incomeComment = "";
      this.incomeSelected = "ไม่ระบุ";
      this.incomeAmount = "0";
    },
    initExpense() {
      this.expenseUpdateDate = this.formatDate(new Date());
      this.updateBy = "";
      this.expenseComment = "";
      this.expenseSelected = "ไม่ระบุ";
      this.expenseAmount = "0";
    },
    // addIncome() {
    //   var anIncome = {
    //     updateDate: this.formatDate(this.incomeUpdateDate),
    //     updateBy: "System",
    //     description: this.incomeComment,
    //     monetaryCategory: this.incomeSelected,
    //     branch: "ลาดพร้าว",
    //     income: this.formatPrice(this.incomeAmount),
    //     expense: 0
    //   };

    //   this.orders.push(anIncome);
    //   this.initIncome();
    //   this.incomePanelActive = false;
    // },
    // addExpense() {
    //   var anExpense = {
    //     updateDate: this.formatDate(this.expenseUpdateDate),
    //     updateBy: "System",
    //     description: this.expenseComment,
    //     monetaryCategory: this.expenseSelected,
    //     branch: "ค่าไลน์แมน",
    //     expense: this.formatPrice(this.expenseAmount),
    //     income: 0
    //   };

    //   this.orders.push(anExpense);
    //   this.initExpense();
    //   this.expensePanelActive = false;
    // },
    incomePanel(event) {
      event.stopPropagation();
      this.incomePanelActive = true;
    },
    expensePanel(event) {
      event.stopPropagation();
      this.expensePanelActive = true;
    },

    newCheckIn: function(newDate) {
      this.theCheckIn = newDate;
    },
    newCheckOut: function(newDate) {
      this.theCheckOut = newDate;
    },

    changeToReturn: function(event) {
      event.stopPropagation();
      this.popupActive = true;
    },
    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    }
  }
};
</script>

<style>
.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
  padding-left: 20px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.changetoreturn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

/* VUE HOTEL  */

.red-round-btn {
  background: #e95455;
  border: 1px solid #9e0619;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.green-round-btn {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.income-panel header {
  background-color: #7ed321;
  color: white !important;
}
.income-panel header {
  background-color: #7ed321;
  color: white !important;
}

.expense-panel header {
  background-color: #e95455;
  color: white !important;
}

.dropdown-toggle .clear {
  display: none;
}
.grand-total {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.datepicker__tooltip {
  display: none !important;
}
</style>
